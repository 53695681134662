

.doubleInputContainer {
    position: relative;
    margin-left: 38px !important;
  }
  
  .doubleInput {
    position: relative;
    
    display: inline-block !important;
    width: calc(100%/2 - 10%) !important;
  }
  
  .doubleInput input {
    position: relative !important;
    width: 100%;
    text-align: center !important;
    border-radius: 0;
    left:0;
    border-radius: 0 !important;
    padding: 0 !important;
  }