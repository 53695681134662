
.checkboxContainer {
    position: relative;
    display: inline-block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid black;
    /* background-color: sienna; */

    cursor: pointer;
}

.checkboxBox {
    display: inline-block;
    opacity: 0;
    width: 0;
    height: 0;
}

.checkboxMark {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    /* background-color: red; */
}

.checkboxContainer:hover input ~ .checkboxMark {
    background-color: rgba(0,0,0,0.15);
}

.checkboxContainer input:checked ~ .checkboxMark {
    background-color: rgba(0,0,0,0.5);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkboxMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkboxMark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkboxMark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}