.multiSliderComponent {
    position: relative;
    height: 30px;
    width: 250px;
    margin-top: 10px;

}

.rangeMinLabel {
    position: absolute;
    left: 4px;
    /* width: 7.5%; */
    top: calc(50% - 15px/2);
    font-size: 15px;
    
}

.rangeMaxLabel {
    position: absolute;
    right: 4px;
    /* width: 7.5%; */
    top: calc(50% - 15px/2);
    font-size: 15px;
}

.railOuter {
    position: absolute;
    width: 100%;
    height: 30px;
    transform: translate(0%, -50%);
    border-radius: 7;
    cursor: pointer;
}

.railInner {    
    position: absolute;
    width: 100%;
    height: 5px;
    transform: translate(0%, -50%);
    border-radius: 7;
    pointer-events: none;
    background-color: rgb(155,155,155);
}
.slider-tracks {
    background-color: green;
}

.employer-slider {
    position: relative;
    left: 0%;
}

