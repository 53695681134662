



.edit-page-btns {
    position: relative;
    bottom: 130px;
    left: 270px;
}

.edit-page-btns button {
    width: 150px;
    border-radius: 4px;
    font-weight: 600;
    

}

.edit-save-button {
    background-color: white;
    color: rgb(0,117,175);
	height: 32pt;
	line-height: 32pt;
	padding: 0;
	font-size: 13pt;
    width: 125px;
    text-align: center;
}

.edit-save-button:hover {
    color: rgb(209,157,91);
    background-color: white;
	height: 32pt;
	line-height: 32pt;
	padding: 0;
	font-size: 13pt;
}

.edit-page-button {
    background-color:rgb(81,154,210);
}




.employer-name {
    width: 40%;
    position: relative;
    bottom: 20px;
}

.employer-name h2 {
    margin-top: 50px;;
}

.employer-name img {
    position: relative;
    top: 40px;
    left: 170px;
}

.employer-name input[type=text]{
    width: 40%;
}


.hrContact {
    width: 50%;
    margin-top: 20px;
    padding-bottom: 20px;
    
} */

.hrContact button img {
    position: relative;
    left: 260px;
}

.hrContact a {
    width: 100%;
    position: relative;
    left: 320px;
    bottom: 45px;
    margin: 0 15px;
}


.hrContact a img   {
    margin-left: 10px;
    width: 15px;

}

.hrContactEmailElement {
    position: relative;
    margin: 15px 0;
    font-size: 18px;
    
}


.btnHrContactRemoveEmail {
    position: relative;
    margin-right: 10px;
    margin-left: 10px;
    top: 5px;
    cursor: pointer;
  
   
}

.hrContact_SendEmail {
    display: inline-block;
    text-align: center;
    width: 120px;
    margin-right: 10px;
    padding: 10px 8px;
    font-size: 14px;
    border-radius: 10px;;
    cursor: pointer;
    background-color: var(--color-button);
    color: white;
    text-transform: uppercase;
}

.hrCheckbox {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    margin-right: 5px;
    width: 25px;
    height: 25px;
}

.addNewPlan {
    position: relative;
    left: 50px;
    display: inline-block;
    /* bottom: 40px; */
    color: rgb(0,117,175);
}

.addNewEmployerEmail {
    position: relative;
    left: 50px;
    display: inline-block;
    /* bottom: 40px; */
    color: rgb(0,117,175);
}



.manageEmployerContainer {
    position: relative;

}

.card-table-container { 
    display: inline-block;
    overflow: hidden;
    width: "50%"; 
}

/*Employee Table*/

.employee-table-container {
   float: right;
   position: absolute;
   top: 0;
   right: 0;
   width: 50%;
   

  
}


.employee-tabel thead th:nth-child(2) {
    text-align: center;
}

.employee-tabel thead th:nth-child(3) {
    padding-left: 100px;
}
.employee-tabel td:nth-child(3) {
    padding-left: 100px;
}

.employee-tabel td:nth-child(2) {
    text-align: center;
    
    
}

.employee-tabel th input {
    width: 50%;
}




.plan-upload-btn {
  background-color: var(--color-button);
  border-radius: 10px;
  width: 200px;
  text-align: center;  
  padding: 10px 0; 
  color: white;
  margin-right: 2%;
  position: relative;
  bottom: 60px;
}

.plan-upload-btn img {
    margin-left: 15px;
    width: 20px;
      
}

.planYearSelect_container {
    width: 25%;
}


.addNewClaimContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    z-index: 1000;
}

.addNewClaimBox {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    background-color: white;
    border: 1px solid black;
}
