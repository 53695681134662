/*
// Developed by Aptus Engineering, Inc. <https://aptusai.com>
// See LICENSE.md file in project root directory
*/

.DateRangePickerInput__withBorder { 
    border: none !important;
    background-color: rgba(0,0,0,0) !important;
}

.DateInput {
    background-color: rgba(0,0,0,0) !important;
}

.DateInput_input { 
    margin-bottom: 0 !important;
    padding-right: 0 !important;
    border: none !important;
    background-color: rgba(0,0,0,0) !important;
    text-align: center;
}

.DateRangePickerInput_arrow {
    padding: 0px 5px 0px 5px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none !important;
}