.currentEmployersLabel {
    position: relative;
    display: inline-block;
}

.addNewEmployer {
    position: relative;
    display: inline-block;
    transition: all 0.1s ease-in-out;
    left: 250px;
    top: 43px; 
    color: rgb(0, 117, 175);
}

.addNewEmployer:hover {
    transform: scale(1.1);
    cursor: pointer;
}