/*
Claim page  styles last edit 07/21/2020
*/

.claim-subheader {
    color: rgb(140,140,140);
    margin: 30px 0;
    /* bottom: 135px; */
}

.edit-page-button {
    background-color: rgb(0,117,175);
    margin-left: 15px;
}
  


.claimID-container {
    border-bottom: rgb(140,140,140) solid 2px;
    overflow: hidden;
    position: relative;
    
}

.claim-input-title {
   margin-left: 40px;
   margin-top: 30px;
}


.claim-input-title input[type=text]{
  width: 80%;
  background-color: rgb(246, 245, 243);
}

.claim-data-container {

    background-color: rgb(246, 245, 243);
    width: 30%;
    margin-top: 30px;
    margin-right: 2%;
    padding-right: 80px;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 25px;

}

.reimbursement {
    float: right;
    position: absolute;
    top: 0px;
    right: 0px;

    background-color: rgb(246, 245, 243);
    width: 50%;
    margin-top: 30px;
    margin-right: 2%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 25px;
}


.flagged-claim {
    margin-left: 42px;
    margin-top: 18px;
    color: rgb(209,157,91);
}

.view-claim {
    margin-left: 42px;
    margin-top: 12px;
    color: rgb(84, 131, 23);
}

.claimBtn {
    margin: 0px 15px 0px 0px;
    padding: 0px 20px;
    border-radius: 6px;
    font-weight: 700;
}

.uploadReceipt {
  margin-top: 20px;
  border: rgba(0,0,0,0) solid;
}

.viewDownloadBtn {
    background-color: white;
    color: var(--color-button);
    border: rgba(0, 0, 0, 0) solid;
}

.sendReceiptBtn {
  border: rgba(0,0,0,0) solid;
}

.receiptSentDate {
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: large;
}

.flagged-container {

    border: rgb(209,157,91) solid 1px;
    margin-top: 20px;
    padding: 10px 25px;
    text-align: center;
    color: rgb(209,157,91);

}

.viewed-container {

    border: rgb(91,157,209) solid 1px;
    margin-top: 20px;
    padding: 10px 25px;
    text-align: center;
    color: rgb(91,157,209);

}

.alert-button {

    margin-top: 12px;
    border-radius: 4px;
    padding: 6px 16px;
    line-height: normal;
    font-size: 11pt;
    font-weight: 900;
    height: auto;
    margin-right: 20px;

}

.fieldList {

    margin-top: 25px;
    border-top: rgba(225, 225, 225, 225) solid;

    top: 25px;
}

.fieldKey {
    position: relative;
    width: auto !important;
    border: 1px solid black;
    display: inline-block;
    background-color: rgb(246, 245, 243);
    /* margin-right: 15px !important; */
}

.fieldValue {
    position: relative;
    width: auto !important;
    border: 1px solid black;
    display: inline-block;
    margin-left: 25px !important;
    background-color: rgb(246, 245, 243);
}

.fieldAdd {
    display: inline-block;
    font-size: xx-large;
    margin-left: 20px;
    color: var(--color-button);
}

.fieldRemove {
    display: inline-block;
    font-size: xx-large;
    margin-left: 20px;
    color: var(--color-button);
}

.alert-employee-container {
    background-color: rgb(246, 245, 243);
    width: 30%;
    margin-top: 30px;
    margin-right: 2%;
    padding-left: 40px;
    padding-right: 40px;
    /* padding-right: 3%; */
    padding-top: 25px;
    padding-bottom: 25px;
    border-radius: 25px;
    margin-bottom: 25px;

}

.alert-employee-container p {
    font-weight: bold;
    color: rgb(53,53,53);
    font-size: 16px;
    line-height: 1.5em;
}

.checkmark {
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    background-color: rgb(224, 224, 224);
    border-radius: 4px;
    transition: background-color 0.4s;
    margin-top: 18px;
 
}

#check:checked ~.checkmark {
    background-color: rgb(209,157,91);
}

.checkmark::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 15px;
    border-right: white 2px solid;
    border-bottom: white 2px solid;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(40deg) scale(2);
    opacity: 0;
    transition: all 0.4s;
}

#check:checked ~.checkmark::after {
    opacity:  1;
    transform: translate(-50%, -50%) rotateZ(40deg) scale(1);
}




.reimbursement p {
    width: 80%;
    line-height: 1.7em;
    
}

.documents-header {
    margin-top: 80px;
    display: flex;

}

.documents-header a {
     padding-top: 18px;
     margin-left: 50px;
}

.documents-header img {
    width: 20px;
    margin: 0 10px;
}

.documents-container {
    padding: 25px;
    display: inline-block;
    
}

.documents-container-img {
    display: inline-block;
    position: relative;
    padding: 25px;
}

.doc-info {
    width: 40%;
    position: relative;
    left: 180px;
    top: 110px;
    font-size: 16px;
    line-height: 15pt;
    color: rgb(140,140,140);
}

.doc-info span {
   display: block;
   color: rgb(39,66,124);
}   

.doc-info img {
    position: relative;
    right: 10px;
    top: 10px;
}

.edit-page-btns {
    position: relative;
    /* background-color: blue; */
    
    top: 0px;
    left: 0px;
}

.claimForRemoveBtn {
    position: relative;
    right: 20px;
    top: 5px;
}

.choosingEmailsContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.15);
    z-index: 1000;
}

.choosingEmailsBox {
    position: absolute;
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
    background-color: white;
    border: 1px solid black;
}

.choosingEmailsInput {
    position: relative;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 5px;
    width: 25px;
    height: 25px;
}

.duplicateClaimBtn {
    position: relative;
    left: 0px;
    display: inline-block;
    /* bottom: 40px; */
    color: rgb(0,117,175);
    text-decoration: underline;
}

.unhideBtn {
    position: relative;
    left: 0px;
    display: inline-block;
    /* bottom: 40px; */
    background-color: white;
    color: rgb(0,117,175);
}

.hideBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    display: inline-block;
    /* bottom: 40px; */
    color: black;
    /* background-color: white; */
    /* text-shadow:
		-0.5px -0.5px 0 #FFF,
		0.5px -0.5px 0 #FFF,
		-0.5px 0.5px 0 #FFF,
		0.5px 0.5px 0 #FFF; */
    /* text-decoration: underline; */
}

.uploadEob {
    margin-top: 10px;
    margin-right: 30px;
}


.documentUpload {
    /* display: inline-block; */
    /* display: inline-block; */
    background-color: black;
    position: relative;
    left: 0;
    top: 0;
    /* width: 0; */
    height: 180px;
    background-color: rgb(230,230,230);
    opacity: 0.5;
    cursor: pointer;
    border: 2px dashed rgb(200,200,200);
    transition: all 0.2s ease-in-out;
    border-radius: 25px;
    z-index: 5;
}

/* .documentUpload:hover {
    opacity: 1;
} */

.documentUploadTip {
    position: relative;
    height: auto;
    text-align: center;
    left: 0;
    top: calc(50% - 15px);
    font-size: 24px;
    color: rgb(120,120,120);
    background-image: url('/icons/documents.png');
    background-size: 200px 200px;
    background-repeat: no-repeat;
    background-position: 150px 85px;
}

.documentUploadSubtip {
    font-size: 16px;
}