
.search {
    position: absolute;
    right: 28px;
	top: 28px;
	z-index: 1000;

    max-height: 600px !important;
    /* height: 600px !important; */
    overflow: hidden;
    overflow-y: auto;
}

.searchContainer {

    background-color: var(--color-light);
    border-radius: 22px;
}

.search input[type=text] {
    border: none;
    outline: none;
    background-color: rgba(0,0,0,0);
    width: 350px;
    height: 40px;
    padding: 0px 15px 0px 40px;
    /* border-radius: 22px; */
    color: var(--color-text);
    font-size: 16px;
    border-bottom: none !important;
}

.search-icon {
    position: absolute;
    top: 5px;
    left: 6px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
	opacity: 0.7;
	transition: all 0.1s ease-in-out;
}

.search-icon:hover {
	cursor: pointer;
	opacity: 1;
	background-color: rgb(230,230,230);
}

.search-icon img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: 8px;
    opacity: 0.8;
}

.search-results {
	border-top: 2px solid var(--color-secondary-accent);
    width: 100%;
    padding-bottom: 20px;

}

.search-card {
    background-color: var(--color-light);
    width: 100%;
    height: 28px;
    padding-top: 6px;
}

.search-card:hover {
    position: relative;
    background-color: var(--color-text-light);
    cursor: pointer;
}

.search-header {
    font-weight: 500;
    text-transform: capitalize;
}

.search-card-type {
    position: absolute;
    left: 20px;
    font-weight: bold;
}

.search-card-value {
    position: absolute;
    left: 130px;
}
