.tableOuterComponent {
    /* position: relative;
    left: -60px; */
}

.tableComponent {
  position: relative;
  /* left: 60px; */
  padding-right: 60px;
}

.tableContainer {
  border-collapse: collapse;
  width: 100%;
  margin: 80px 0;
}

.tableContainer th, td {
  text-align: left; 
}

.tableContainer tbody tr:nth-child(even) {
  background-color: rgb(246,245,243);
}

.tableContainer tbody tr:nth-child(odd):hover {
  background-color: rgb(39,66,124, .2);
}

.tableContainer tbody tr:nth-child(even):hover {
  background-color: rgb(39,66,124, .2);
}

.tableContainer th {
  color: rgb(140,140,140);
  padding-left: 10px;
  padding-bottom: 15px;
 
}

.tableContainer thead tr {
  border-bottom: rgb(140,140,140) solid 1px !important;
}

.tableContainer td {
  color: rgb(140,140,140);
  font-weight: 400;
  padding: 20px 20px;
  text-align: left;
  /* padding-bottom: 3px; */
}

.tableContainer tbody tr:hover {
  cursor: pointer;
}

.tableContainer input[placeholder] {
  color: rgb(140,140,140);
  font-weight: 500;
  font-size: 12px;
}

.removeTableElement {
    position: absolute;
    z-index: 10;
    /* float: right; */
    /* right: -80px; */
}

.removeTableElement_claimTable {
    right: 80px !important;
}

.removeTableElement_employeeTable {
    right: 80px;    
}

.removeTableElement_employerTable {
    right: 80px;
}

/*Employer Table*/
.employerTable {
  width: 100%;

}

.employer-thead-input {
  width: 60%;
}

.employerTable th:nth-child(3) {
  text-align: center;  
}

.employerTable td:nth-child(3) {
  text-align: center;
}

/*H2 claims table*/
.sub-header {
	color: rgb(39,66,124);   
    margin-top: 15px; 
}

.pageControlBtn {
	color: rgb(39,66,124);
}

.noResultsFound {
  top: -60px;
  position: relative;
}

.searchBoxContainer {
  position: relative;
  background-color: var(--color-light);
  border-radius: 30px;
  margin: 15px 15px 15px 0px;
  
  max-width: 100% !important;
  height: 40px;
}

.activeSearchBox {
  color: rgb(39,66,124) !important;
  font-weight: bold !important;
}

.searchBoxContainer input {
  border: none;
  outline: none;
  background-color: rgba(0,0,0,0);
  /* width: 150px !important; */
  width: 80%;
  height: 40px;
  padding: 1px 15px 0px 20px;
  border-radius: 22px;
  color: var(--color-text);
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: bold;
  text-align: left;
}

.searchIcon {
  position: absolute;
  top: 5px;
  left: 6px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  opacity: 0.7;
  transition: all 0.1s ease-in-out;
}

.searchIcon:hover {
	cursor: pointer;
	opacity: 1;
	background-color: rgb(230,230,230);
}

.searchIcon img {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    margin-top: 8px;
    opacity: 0.8;
}


.tablePageSizeSelect_container {
    position: relative;
    width: 175px;
    display: inline-block;
    
}

.pageCounter {
    position: relative;
    width: 100px;
    display: inline-block;
    margin-left: 15px;
}

.dateRangeSelect_container {
    position: relative;
    width: 170px;
    padding-bottom: 15px;
    display: inline-block;
    margin-right: 15px;
}

.tableHeaderItem {
    cursor: pointer;
}

.createNewClaim {
    position: absolute;
    left: 8px;
    width: 20px;
}

.dateFilterContainer {
    position: relative;
    /* line-height: 35px; */
}

.dateFilterObject {
    vertical-align: center;
    position: relative;
    line-height: 35px;
    width: 100%;
    height: 35px;
    border-radius: 15px;
    margin-top: 15px;    
    background-color: rgba(0,0,0,0.05);
}

/* .dateFilterObject_submit {
    background-color: red;
}

.dateFilterObject_complete {
    background-color: blue;
} */

.dateFilterHeader {
    left: 25px;
    position: relative;
    float: left;
}

.dateFilter {
    position: relative;
    float: right;
}

.dateFilterClearBtn {
    position: relative;
    float: left;
    color: cornflowerblue;
    left: 50px;
}

.toggleByMonth {
    position: absolute;
    right: 320px;
    top: 6px;
    /* height: 50vh; */
}