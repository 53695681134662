/*
// Developed by Aptus Engineering, Inc. <https://aptus.aero>
// See LICENSE.md file in project root directory
*/

* {
	font-family: 'Montserrat', sans-serif;
	--color-primary: rgb(39,66,124);
	--color-secondary: rgb(145,102,51);
	--color-primary-accent: rgb(164,194,219);
	--color-secondary-accent: rgb(209,157,91);
	--color-light: rgb(246,245,243);
	--color-bright: rgb(81,154,210);
	--color-text: rgb(53,53,53);
	--color-text-light: rgb(200,200,200);
	--color-text-medium: rgb(140,140,140);
	--color-button: rgb(0,117,175);
}

/*Login Validation Styles*/
.failed-login {
	margin-top: 5%;
	background-color: rgb(255,132,122);
	color: var(--color-text);
	font-weight: 700;
	padding: 20px 30px;
	border-radius: 4px;
	overflow: hidden;
	
	
}

.failed-login span {
	font-size: 20px;
	display: block;
	margin-top: 5px;
	margin-right: 20px;
	float: left;
	font-size: 24px;
}

nav {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	width: 240px;
	background-color: var(--color-primary);
}

nav .logo {
	width: 128px;
	height: 128px;
	margin-left: 47px;
	margin-top: 10px;
	margin-bottom: 40px;
}

.mySaveBtn {
    background-color: blue;
    border: 1px solid green;
}

.nav-link {
	display: block;
	height: 30px;
	width: calc(100% - 47px);
	color: white;
	font-weight: 300;
	font-size: 15px;
	line-height: 30px;
	margin-bottom: 25px;
	background-position: right;
	padding-left: 50px;
	background-size: 6px 30px;
	background-repeat: no-repeat;
}

.nav-logout {
	position: absolute;
	bottom: 20px;
}

.nav-link img {
	float: left;
	width: 20px;
	height: 20px;
	margin-top: 5px;
	margin-right: 8px;
}

.nav-link-active {
	background-image: url('/icons/link-active.png');
}

.nav-link:hover {
	cursor: pointer;
	color: var(--color-secondary-accent);
	text-decoration: underline;
}

.login-container {
	position: fixed;
	width: 400px;
	height: 700px;
	left: calc(50% - 200px);
	top: calc(50% - 400px);
}

.login-container img {
	width: 200px;
	margin-top: 20px;
	margin-left: 100px;
	margin-bottom: 50px;
}

.login-container img:hover {
	cursor: pointer;
}

.portal-container {
	position: relative;
	margin-left: 240px;
	padding-left: 60px;
	padding-top: 150px;
}

h1 {
	font-size: 26px;
	color: var(--color-primary);
	font-weight: 500;
	line-height: 1.5em;
}

h2 {
	font-size: 20px;
	color: rgb(39,66,124);
	font-weight: 400;
	line-height: 1.5em;	
}

h3 {
	font-size: 26px;
	color: var(--color-text);
	font-weight: 500;
	line-height: 1.5em;
	position: relative;
	padding-bottom: 5px;
}

h3:before {
	content: "";
	position: absolute;
	width: 60px;
	height: 1px;
	bottom: 0;
	left: 0;
	border-bottom: 2px solid var(--color-primary); 
}

.login-container h2 {
	margin-top: -0.5em;
	margin-bottom: 2.5em;
}

label {
	display: block;
	color: var(--color-text);
	font-size: 11pt;
	line-height: 2em;
	margin-top: 20pt;
}

input[type=text], input[type=password] {
	width: 100%;
	border: none;
	border-bottom: 2px solid rgb(230,230,230);
	padding: 0;
	height: 28pt;
	line-height: 28pt;
	font-size: 14pt;
	color: var(--color-text);
	margin: 0;
}

input[type=text]:focus, input[type=password]:focus {
	box-shadow: none;
	outline: none;
	border-bottom: 2px solid var(--color-secondary-accent);
}

button {
	background-color: var(--color-button);
	border: none;
	outline: none;
	color: white;
	height: 32pt;
	line-height: 32pt;
	padding: 0;
	/* margin-top: 48pt; */
	font-size: 13pt;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	border-radius: 16pt;
	box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.15);
}

btn {
	background-color: var(--color-button);
	border: none;
	outline: none;
	color: white;
	text-transform: uppercase;
	font-family: 'Raleway', sans-serif;
	border-radius: 16pt;
	box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.15);
    text-align: center;
}

button:hover, button:focus {
	cursor: pointer;
	background-color: var(--color-secondary-accent);
}

a {
	color: var(--color-bright);
	text-decoration: none;
	font-weight: 600;
	font-size: 14px;
}

.plans {
	display: flex;
}

.plans a {
	margin-top: 25px;
	margin-left: 10px;
}

.clickable {
	transition: all 0.1s ease-in-out;
}

.clickable:hover {
	transform: scale(1.2);
	cursor: pointer;
}

.clickableUploadButton {
    transition: all 0.1s ease-in-out;
    transform-origin: 25% 25%;
}

.clickableUploadButton:hover {
    transform: scale(1.2);
	cursor: pointer;
}
