/*
 Dashboard Styles last edit 07/21/2020
*/

.sub-header {
	color: rgb(39,66,124);
    margin-top: 50px;
    
}

.sub-header span {
    font-size: 16px;
    margin-left: 10px;
    color: rgb(123, 123, 123);
    font-weight: 800;
}



.notificationList {
	/* display: inline-block; */
    position: relative;
   
	
}

.notification img {
    margin-top: 10px;
    margin-right: 20px;

} 

.view-claims-link {
    z-index: 1;
    position: relative;
    width: 130px;
    top: 65px;
    
    color:rgb(81,154,210);
    left: 220px;
    margin-bottom: 25px;
    display: inline-block;
}
	
.notificationTitle:hover {
    cursor: pointer;
}

.notificationCaret {
    width: 50px;
}
