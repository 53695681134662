

.plan-card {
    box-shadow: 0 3px 6px rgb(196, 196, 196);
    padding: 20px;
    position: relative;
    overflow: hidden;
    margin: 30px 0;
    width: fit-content;

}

.plan-card h4 {
    font-size: 18px;
    color: rgb(209,157,91);
    font-weight: 700;
    padding: 0;
    margin: 0;
    width: 100%;
}

.upload-btn-container {
   color: var(--color-button);
    margin: 10px 0 40px 0;


}

.upload-btn {
    margin-left: 15px;
    width: 15px;
}

.upload-btn-container:hover {
    cursor: pointer;
}

.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 5;
}

.close-btn:hover {
    cursor: pointer;
}

.plan-card-inputbox  {
    width: 100%;
}


.plan-card-inputbox input[type=text] {
    width: 100%;

}



.plan-card-inputbox textarea {
    border: 2px solid rgb(230,230,230);
    border-radius: 4px;
    border-top: none;
    border-left: none;
    border-right: none;

}

.placeholder-plan-text {
    position: absolute;
    bottom: 2%;
    right: 0;
    margin-right: 11%;

}

.planImg img {
    position: relative;
    /* top: 30px; */
    max-height: 90%;
    bottom: 0;
    object-fit: contain;
}

.planImg {
    width: 100%;
    overflow: hidden;
    position: relative;
    bottom: 100px;
    display: inline-block;
    width: 50%;
    top: 0;
    z-index: 4;
}

.planCardInput_noHover {
	border-bottom: 2px solid rgb(230,230,230) !important;
}

.planCardInput_noHover:focus {
    border-bottom: 2px solid rgb(230,230,230) !important;
}